var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import Modal from "react-modal";
var ProductEdit = /** @class */ (function (_super) {
    __extends(ProductEdit, _super);
    function ProductEdit(props) {
        var _this = _super.call(this, props) || this;
        _this.handleChange = function (evt) {
            _this.setState({
                selectValue: evt.target.value
            });
            console.log(_this.state.selectValue);
        };
        _this.state = {
            categories: [],
            selectValue: 5
        };
        return _this;
    }
    ProductEdit.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var r, categories, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        Modal.setAppElement('body');
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, fetch('/api/product-categories')];
                    case 2:
                        r = _a.sent();
                        return [4 /*yield*/, r.json()];
                    case 3:
                        categories = _a.sent();
                        this.setState({ categories: categories });
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        console.log(error_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ProductEdit.prototype.render = function () {
        return (React.createElement(Modal, { isOpen: this.props.show, className: "modal-middle" },
            React.createElement("form", { action: "" },
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { htmlFor: "productNumber" }, "Produktnummer"),
                    React.createElement("input", { type: "text", name: "productNumber", id: "productNumber", className: "form-control", defaultValue: this.props.productNumber })),
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { htmlFor: "productName" }, "Produktname"),
                    React.createElement("input", { type: "text", name: "productName", id: "productName", className: "form-control", defaultValue: this.props.productName })),
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { htmlFor: "productCategories" }, "Produktkategorie"),
                    React.createElement("select", { name: "productCategories", id: "productCategories", className: "form-control", value: this.props.productCategory, onChange: this.handleChange, required: true },
                        React.createElement("option", null, "bitte w\u00E4hlen ..."),
                        this.state.categories.map(function (category, key) {
                            return (React.createElement("option", { value: category.id, key: key }, category.categoryName));
                        }))),
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { htmlFor: "productDescription" }, "Produktbeschreibung"),
                    React.createElement("textarea", { name: "productDescription", id: "productDescription", className: "form-control", defaultValue: this.props.productDescription })),
                React.createElement("div", { className: "form-group mb-0 text-right float-right" },
                    React.createElement("button", { type: "submit", className: "btn btn-secondary save" }, "Aktualisieren")),
                React.createElement("div", { className: "form-group mb-0 text-right float-right" },
                    React.createElement("button", { type: "button", className: "btn btn-secondary cancel", onClick: this.props.closeModalHandler }, "Schlie\u00DFen")))));
    };
    return ProductEdit;
}(Component));
export default ProductEdit;
