var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
var ButtonConfirm = /** @class */ (function (_super) {
    __extends(ButtonConfirm, _super);
    function ButtonConfirm() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ButtonConfirm.prototype.render = function () {
        var _this = this;
        var btnStdClass = "btn btn-secondary ";
        var btnClass = this.props.btnClass ? btnStdClass + this.props.btnClass : btnStdClass;
        var faClass = "fa fa-" + this.props.faClass;
        var disabled = this.props.disabled ? true : false;
        var display = this.props.display ? React.createElement("span", null, this.props.display) : "";
        return (React.createElement("button", { className: btnClass, onClick: function () { if (window.confirm(_this.props.confirmMessage))
                _this.props.clickHandler(_this.props.id); }, disabled: disabled },
            React.createElement("i", { className: faClass, title: this.props.title }),
            display));
    };
    return ButtonConfirm;
}(React.Component));
export default ButtonConfirm;
