var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import Button from "../layout/content/Button";
var CategoryNew = /** @class */ (function (_super) {
    __extends(CategoryNew, _super);
    function CategoryNew() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CategoryNew.prototype.render = function () {
        var btnClass = "btn btn-secondary " + this.props.buttonIcon;
        return (React.createElement("div", null,
            React.createElement("h5", null, "Neue Kategorie"),
            React.createElement("div", { className: "container mb-5" },
                React.createElement("form", { onSubmit: this.props.categorySaveHandler, id: "categorySave" },
                    React.createElement("div", { className: "form-group" },
                        React.createElement("label", { htmlFor: "categoryName" }, "Kategoriename"),
                        React.createElement("input", { type: "text", className: "form-control", name: "categoryName", id: "categoryName", value: this.props.value, onChange: this.props.categoryExistsHandler, required: true })),
                    React.createElement("div", { className: "form-group mb-0 text-right" },
                        React.createElement(Button, { btnClass: btnClass, faClass: this.props.buttonIcon, display: this.props.buttonSaveValue, disabled: this.props.disabled }))))));
    };
    return CategoryNew;
}(React.Component));
export default CategoryNew;
