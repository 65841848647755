var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { HashRouter as Router, } from 'react-router-dom';
import url from 'url';
import Sidebar from './elements/layout/sidebar/Sidebar';
import Content from './elements/layout/content/Content';
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App(props) {
        var _this = _super.call(this, props) || this;
        _this.changeCategoryHandler = function (category) {
            _this.setState({
                category: category
            });
        };
        _this.changeYearHandler = function (year) {
            _this.setState({
                year: year
            });
        };
        _this.changeAssignmentNumberHandler = function (assignmentNumber) {
            _this.setState({
                assignmentNumber: assignmentNumber
            });
        };
        _this.state = {
            category: "",
            year: url.parse(window.location.href).href.split("/").pop(),
            assignmentNumber: 0
        };
        _this.changeYearHandler = _this.changeYearHandler.bind(_this);
        _this.changeCategoryHandler = _this.changeCategoryHandler.bind(_this);
        _this.changeAssignmentNumberHandler = _this.changeAssignmentNumberHandler.bind(_this);
        return _this;
    }
    App.prototype.render = function () {
        return (React.createElement("main", { className: "" },
            React.createElement("div", { className: "row" },
                React.createElement(Router, null,
                    React.createElement("div", { className: "col-lg-3" },
                        React.createElement(Sidebar, { changeYearHandler: this.changeYearHandler, changeCategoryHandler: this.changeCategoryHandler, category: this.state.category })),
                    React.createElement("div", { className: "col-lg-9 content" },
                        React.createElement(Content, { year: this.state.year, changeAssignmentNumberHandler: this.changeAssignmentNumberHandler, assignmentNumber: this.state.assignmentNumber }))))));
    };
    return App;
}(Component));
export default App;
