var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Button.prototype.render = function () {
        var _this = this;
        var btnStdClass = "btn btn-secondary ";
        var btnClass = this.props.btnClass ? btnStdClass + this.props.btnClass : btnStdClass;
        var faClass = "fa fa-" + this.props.faClass;
        var display = this.props.display ? React.createElement("span", null, this.props.display) : "";
        var disabled = this.props.disabled ? true : false;
        var dataToggle = this.props.dataToggle ? "modal" : "";
        var dataTarget = this.props.dataTarget ? this.props.dataTarget : "";
        var dataDismiss = this.props.dataDismiss ? this.props.dataDismiss : "";
        return (React.createElement("button", { className: btnClass, onClick: function () { return _this.props.clickHandler ? _this.props.clickHandler(_this.props) : null; }, onChange: function () { return _this.props.changeHandler ? _this.props.changeHandler(_this.props) : null; }, disabled: disabled, "data-toggle": dataToggle, "data-target": dataTarget, "data-dismiss": dataDismiss },
            React.createElement("i", { className: faClass, title: this.props.title }),
            display));
    };
    return Button;
}(React.Component));
export default Button;
