var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
var CustomerNew = /** @class */ (function (_super) {
    __extends(CustomerNew, _super);
    function CustomerNew(props) {
        var _this = _super.call(this, props) || this;
        _this.customerSalutationChangeHandler = function (event) {
            _this.setState({
                customerSalutation: event.target.value
            });
        };
        _this.customerFirstnameChangeHandler = function (event) {
            _this.setState({
                customerFirstname: event.target.value
            });
        };
        _this.customerLastnameChangeHandler = function (event) {
            _this.setState({
                customerLastname: event.target.value
            });
        };
        _this.state = {
            customerSalutation: "",
            customerFirstname: "",
            customerLastname: "",
            buttonIcon: "save",
            button: "speichern"
        };
        return _this;
    }
    CustomerNew.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                }
                catch (error) {
                    console.log(error);
                }
                return [2 /*return*/];
            });
        });
    };
    CustomerNew.prototype.render = function () {
        var btnClass = "btn btn-secondary " + this.state.buttonIcon;
        return (React.createElement("div", null,
            React.createElement("h5", null, "Neue Kunde"),
            React.createElement("div", { className: "container mb-5" },
                React.createElement("form", { onSubmit: this.props.customerSaveHandler },
                    React.createElement("input", { type: "hidden", name: "customerNumber", id: "customerNumber", value: this.props.customerNumber }),
                    React.createElement("div", { className: "form-group" },
                        React.createElement("label", { htmlFor: "customerSalutation" }, "Anrede"),
                        React.createElement("select", { name: "customerSalutation", id: "customerSalutation", className: "form-control", onChange: this.customerSalutationChangeHandler, required: true },
                            React.createElement("option", { value: "" }, "bitte w\u00E4hlen ..."),
                            React.createElement("option", { value: "Herr" }, "Herr"),
                            React.createElement("option", { value: "Frau" }, "Frau"))),
                    React.createElement("div", { className: "form-group" },
                        React.createElement("label", { htmlFor: "customerFirstname" }, "Vorname"),
                        React.createElement("input", { type: "text", className: "form-control", name: "customerFirstname", id: "customerFirstname", onChange: this.customerFirstnameChangeHandler, required: true })),
                    React.createElement("div", { className: "form-group" },
                        React.createElement("label", { htmlFor: "customerLastname" }, "Nachname"),
                        React.createElement("input", { type: "text", className: "form-control", name: "customerLastname", id: "customerLastname", onChange: this.customerLastnameChangeHandler, required: true })),
                    React.createElement("div", { className: "form-group mb-0 text-right" },
                        React.createElement("button", { type: "submit", className: btnClass }, this.state.button))))));
    };
    return CustomerNew;
}(Component));
export default CustomerNew;
