var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import url from 'url';
import { Link } from 'react-router-dom';
var NavigationItem = /** @class */ (function (_super) {
    __extends(NavigationItem, _super);
    function NavigationItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NavigationItem.prototype.render = function () {
        var _this = this;
        var icon = this.props.icon ? this.props.icon : "";
        var className = "";
        var visible = this.props.href ? "list-group-item" : "d-none";
        var active = url.parse(window.location.href).href.indexOf(this.props.href) ? "active" : "";
        className = visible + " " + active;
        return (React.createElement(Link, { to: this.props.href, className: className, onClick: function () { return _this.props.changeYear ? _this.props.changeYear(_this.props.value) : null; } },
            React.createElement("i", { className: icon }),
            this.props.value));
    };
    NavigationItem.defaultProps = {
        icon: "fa fa-bars"
    };
    return NavigationItem;
}(React.Component));
export default NavigationItem;
