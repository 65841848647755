var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import Button from "../layout/content/Button";
import Modal from "react-modal";
var CategoryEdit = /** @class */ (function (_super) {
    __extends(CategoryEdit, _super);
    function CategoryEdit(props) {
        var _this = _super.call(this, props) || this;
        _this.categoryName = function (event) {
            _this.setState({
                categoryName: event.target.value
            });
        };
        _this.state = ({
            categoryName: ""
        });
        return _this;
    }
    CategoryEdit.prototype.componentDidMount = function () {
        Modal.setAppElement('body');
    };
    CategoryEdit.prototype.render = function () {
        var value = this.state.categoryName ? this.state.categoryName : this.props.categoryName;
        return (React.createElement(Modal, { isOpen: this.props.show, className: "modal-small" },
            React.createElement("form", { onSubmit: this.props.categoryUpdateHandler },
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { htmlFor: "updateCategory" }, "Neuer Kategoriename"),
                    React.createElement("input", { type: "text", className: "form-control", id: "updateCategory", name: "updateCategory", value: value, onChange: this.categoryName, required: true })),
                React.createElement("div", { className: "form-group text-right" },
                    React.createElement(Button, { faClass: "save", btnClass: "btn btn-secondary update", title: "aktualisieren", display: "aktualisieren" }),
                    React.createElement(Button, { faClass: "ban", btnClass: "btn btn-secondary cancel", title: "abbrechen", display: "abbrechen", clickHandler: this.props.categoryUpdateCancelHandler })))));
    };
    return CategoryEdit;
}(Component));
export default CategoryEdit;
