var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import NavigationItem from './NavigationItem';
import NavigationItemToggle from './NavigationItemToggle';
var Navigation = /** @class */ (function (_super) {
    __extends(Navigation, _super);
    function Navigation($props) {
        var _this = _super.call(this, $props) || this;
        _this.state = {
            offerYears: [],
            assignmentYears: [],
            billYears: []
        };
        return _this;
    }
    Navigation.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var rOffers, offerYears, rBills, billYears, rAssignments, assignmentYears, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 7, , 8]);
                        return [4 /*yield*/, fetch('/api/offer-years')];
                    case 1:
                        rOffers = _a.sent();
                        return [4 /*yield*/, rOffers.json()];
                    case 2:
                        offerYears = _a.sent();
                        this.setState({ offerYears: offerYears });
                        return [4 /*yield*/, fetch('/api/bill-years')];
                    case 3:
                        rBills = _a.sent();
                        return [4 /*yield*/, rBills.json()];
                    case 4:
                        billYears = _a.sent();
                        this.setState({ billYears: billYears });
                        return [4 /*yield*/, fetch('/api/assignment-years')];
                    case 5:
                        rAssignments = _a.sent();
                        return [4 /*yield*/, rAssignments.json()];
                    case 6:
                        assignmentYears = _a.sent();
                        this.setState({ assignmentYears: assignmentYears });
                        return [3 /*break*/, 8];
                    case 7:
                        error_1 = _a.sent();
                        console.log(error_1);
                        return [3 /*break*/, 8];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    Navigation.prototype.render = function () {
        var _this = this;
        return (React.createElement("nav", null,
            React.createElement("div", { className: "list-group" },
                React.createElement(NavigationItem, { href: "/", value: "Dashboard", icon: "" }),
                React.createElement(NavigationItemToggle, { href: "#collapseProducts", value: "Produkte", category: "produkte", changeCategory: this.props.changeCategoryHandler }),
                React.createElement("div", { className: "collapse list-group-submenu", id: "collapseProducts" },
                    React.createElement(NavigationItem, { href: "/produkte/anzeigen", value: "Anzeigen" }),
                    React.createElement(NavigationItem, { href: "/kategorien", value: "Kategorien" })),
                React.createElement(NavigationItemToggle, { href: "#collapseCustomers", value: "Kunden", category: "kunden", changeCategory: this.props.changeCategoryHandler }),
                React.createElement("div", { className: "collapse list-group-submenu", id: "collapseCustomers" },
                    React.createElement(NavigationItem, { href: "/kunden/anzeigen", value: "Anzeigen" })),
                React.createElement(NavigationItemToggle, { href: "#collapseOffers", value: "Angebote", category: "angebote", changeCategory: this.props.changeCategoryHandler }),
                React.createElement("div", { className: "collapse list-group-submenu", id: "collapseOffers" },
                    React.createElement("div", null,
                        React.createElement(NavigationItem, { href: "neues-angebot", value: "Neues Angebot", icon: "fa fa-plus", changeYear: this.props.changeYearHandler })),
                    this.state.offerYears.map(function (year) {
                        var route = "/angebote/" + year["aYear"];
                        return (React.createElement("div", { key: year["aYear"] },
                            React.createElement(NavigationItem, { href: route, value: year["aYear"], icon: "fa fa-calendar", changeYear: _this.props.changeYearHandler })));
                    })),
                React.createElement(NavigationItemToggle, { href: "#collapseAssignments", value: "Auftr\u00E4ge", category: "auftraege", changeCategory: this.props.changeCategoryHandler }),
                React.createElement("div", { className: "collapse list-group-submenu", id: "collapseAssignments" }, this.state.assignmentYears.map(function (year) {
                    var route = "/auftraege/" + year["aYear"];
                    return (React.createElement("div", { key: year["aYear"] },
                        React.createElement(NavigationItem, { href: route, value: year["aYear"], icon: "fa fa-calendar", changeYear: _this.props.changeYearHandler })));
                })),
                React.createElement(NavigationItemToggle, { href: "#collapseBills", value: "Rechnungen", category: "rechnungen", changeCategory: this.props.changeCategoryHandler }),
                React.createElement("div", { className: "collapse list-group-submenu", id: "collapseBills" }, this.state.billYears.map(function (year) {
                    var route = "/rechnungen/" + year["aYear"];
                    return (React.createElement("div", { key: year["aYear"] },
                        React.createElement(NavigationItem, { href: route, value: year["aYear"], icon: "fa fa-calendar", changeYear: _this.props.changeYearHandler })));
                })))));
    };
    return Navigation;
}(React.Component));
export default Navigation;
