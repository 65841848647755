var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import Modal from "react-modal";
var CustomerEdit = /** @class */ (function (_super) {
    __extends(CustomerEdit, _super);
    function CustomerEdit(props) {
        var _this = _super.call(this, props) || this;
        _this.isScForumChange = function () {
        };
        _this.state = {};
        return _this;
    }
    CustomerEdit.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                Modal.setAppElement('body');
                return [2 /*return*/];
            });
        });
    };
    CustomerEdit.prototype.render = function () {
        return (React.createElement(Modal, { isOpen: this.props.show, className: "modal-large" },
            React.createElement("form", { action: "" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-lg-4" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "customerNumber" }, "Kundennummer"),
                            React.createElement("input", { type: "text", name: "customerNumber", id: "customerNumber", className: "form-control", defaultValue: this.props.customerNumber, disabled: true })),
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "company" }, "Firma"),
                            React.createElement("input", { type: "text", name: "company", id: "company", className: "form-control", defaultValue: this.props.company })),
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "customerName" }, "Vorname"),
                            React.createElement("input", { type: "text", name: "customerFirstname", id: "customerFirstname", className: "form-control", defaultValue: this.props.customerFirstname })),
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "customerDescription" }, "Nachname"),
                            React.createElement("input", { type: "text", name: "customerLastname", id: "customerLastname", className: "form-control", defaultValue: this.props.customerLastname }))),
                    React.createElement("div", { className: "col-lg-4" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "address" }, "Adresse"),
                            React.createElement("input", { type: "text", name: "address", id: "address", className: "form-control", defaultValue: this.props.address })),
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "postcode" }, "Postleizahl"),
                            React.createElement("input", { type: "text", name: "postcode", id: "postcode", className: "form-control", defaultValue: this.props.postcode })),
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "city" }, "Stadt"),
                            React.createElement("input", { type: "text", name: "city", id: "city", className: "form-control", defaultValue: this.props.city })),
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "email" }, "E-Mail"),
                            React.createElement("input", { type: "text", name: "email", id: "email", className: "form-control", defaultValue: this.props.email })),
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "phone" }, "Telefon"),
                            React.createElement("input", { type: "text", name: "phone", id: "phone", className: "form-control", defaultValue: this.props.phone })),
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "www" }, "Internet"),
                            React.createElement("input", { type: "text", name: "www", id: "www", className: "form-control", defaultValue: this.props.www }))),
                    React.createElement("div", { className: "col-lg-4" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "vat" }, "VAT"),
                            React.createElement("input", { type: "text", name: "vat", id: "vat", className: "form-control", defaultValue: this.props.vat })),
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "iban" }, "IBAN"),
                            React.createElement("input", { type: "text", name: "iban", id: "iban", className: "form-control", defaultValue: this.props.iban })),
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "bic" }, "BIC"),
                            React.createElement("input", { type: "text", name: "bic", id: "bic", className: "form-control", defaultValue: this.props.bic })),
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "notice" }, "Notiz"),
                            React.createElement("textarea", { name: "notice", id: "notice", className: "form-control", defaultValue: this.props.notice })),
                        React.createElement("div", { className: "form-group" },
                            React.createElement("label", { htmlFor: "isScForum" }, "ScForum Mitglied?"),
                            React.createElement("input", { type: "checkbox", name: "isScForum", id: "isScForum", className: "form-control", checked: this.props.isScForum, onChange: this.isScForumChange })))),
                React.createElement("div", { className: "form-group mb-0 text-right float-right" },
                    React.createElement("button", { type: "submit", className: "btn btn-secondary save" }, "Aktualisieren")),
                React.createElement("div", { className: "form-group mb-0 text-right float-right" },
                    React.createElement("button", { type: "button", className: "btn btn-secondary cancel", onClick: this.props.closeModalHandler }, "Schlie\u00DFen")))));
    };
    return CustomerEdit;
}(Component));
export default CustomerEdit;
