var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
var DateFormatter = /** @class */ (function (_super) {
    __extends(DateFormatter, _super);
    function DateFormatter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DateFormatter.prototype.render = function () {
        return (React.createElement("span", null, this.setFormat(this.props.date, this.props.format)));
    };
    DateFormatter.prototype.setFormat = function (date, format) {
        try {
            var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            switch (format) {
                case "dd.mm": {
                    return [day, month].join('.');
                }
                case "mm.yy": {
                    return [month, year].join('.');
                }
                case "yy": {
                    return [year].join('.');
                }
                default: {
                    return [day, month, year].join('.');
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };
    return DateFormatter;
}(Component));
export default DateFormatter;
