var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { Component } from 'react';
import CategoryNew from './CategoryNew';
import CategoryList from './CategoryList';
var CategoryView = /** @class */ (function (_super) {
    __extends(CategoryView, _super);
    function CategoryView(props) {
        var _this = _super.call(this, props) || this;
        /**
         * Prüfen, ob die zu erstellende Kategorie bereits vorhanden ist
         */
        _this.categoryExistsHandler = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var r, categoryExists;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setState({
                            categoryName: event.target.value
                        });
                        return [4 /*yield*/, fetch('/api/category-exists/' + event.target.value)];
                    case 1:
                        r = _a.sent();
                        return [4 /*yield*/, r.json()];
                    case 2:
                        categoryExists = _a.sent();
                        if (categoryExists) {
                            this.setState({
                                buttonIcon: "exclamation",
                                buttonSaveValue: "existiert bereits",
                                disabled: true
                            });
                        }
                        else {
                            this.setState({
                                buttonIcon: "save",
                                buttonSaveValue: "speichern",
                                disabled: false
                            });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        /**
         * Speichern einer neuen Kategorie
         */
        _this.categorySaveHandler = function (event) {
            event.preventDefault();
            var self = _this;
            var category = {
                categoryName: _this.state.categoryName
            };
            var newCategory = {
                categoryName: _this.state.categoryName
            };
            _this.setState({
                categories: __spreadArrays(_this.state.categories, [
                    newCategory
                ]),
                buttonIcon: "spinner",
                buttonSaveValue: "wird gespeichert"
            });
            fetch('/api/category-save', {
                method: 'post',
                body: JSON.stringify(category)
            })
                .then(function (response) {
                self.setState({
                    buttonIcon: "check",
                    buttonSaveValue: "gespeichert"
                });
            })
                .then(function () {
                self.setState({
                    buttonIcon: "save",
                    buttonSaveValue: "speichern",
                    categoryName: ""
                });
            })
                .catch(function (error) {
                console.log(error);
            });
        };
        /**
         * Setzt die ID der zu aktualisierenden Kategorie für die Update-SQL
         */
        _this.categoryUpdateParamHandler = function (props) { return __awaiter(_this, void 0, void 0, function () {
            var r, categories, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch('/api/category-by-id/' + props.id)];
                    case 1:
                        r = _a.sent();
                        return [4 /*yield*/, r.json()];
                    case 2:
                        categories = _a.sent();
                        if (categories) {
                            this.setState({
                                categoryName: categories[0].categoryName
                            });
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.log(error_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.setState({
                            categoryIdToUpdate: props.id,
                            show: true
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        /**
         * Aktualisiert die selektierte Kategorie
         */
        _this.categoryUpdateHandler = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var self, data, id, updateCategory, r, categories;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        self = this;
                        data = new FormData(event.target);
                        id = this.state.categoryIdToUpdate;
                        updateCategory = data.get('updateCategory');
                        fetch('/api/category-update/' + id + "/" + updateCategory, {
                            method: 'post',
                            body: data
                        })
                            .then(function (response) {
                        })
                            .catch(function (err) {
                            console.log(err);
                        });
                        return [4 /*yield*/, fetch('/api/product-categories')];
                    case 1:
                        r = _a.sent();
                        return [4 /*yield*/, r.json()];
                    case 2:
                        categories = _a.sent();
                        this.setState({
                            categories: categories,
                            show: false
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        /**
         * Schließt das Bearbeiten-Modal bei Klick auf abbrechen
         */
        _this.categoryUpdateCancelHandler = function () {
            _this.setState({
                show: false
            });
        };
        /**
         * Löscht die selektierte Kategorie. Dies muss vorher bestätigt werden
         */
        _this.categoryDeleteHandler = function (id) {
            var self = _this;
            fetch('/api/category-delete', {
                method: 'post',
                body: JSON.stringify(id)
            })
                .then(function (response) {
            })
                .catch(function (error) {
                console.log(error);
            });
            var newCategories = __spreadArrays(_this.state.categories);
            newCategories.filter(function (category, index) {
                if (category.id == id) {
                    if (index > -1) {
                        newCategories.splice(index, 1);
                    }
                    self.setState({
                        categories: newCategories
                    });
                }
                return index;
            });
        };
        _this.state = {
            categories: [],
            categoryName: "",
            categoryIdToUpdate: 0,
            buttonSaveValue: "speichern",
            buttonUpdateValue: "aktualisieren",
            buttonIcon: "save",
            value: "",
            show: false,
        };
        _this.categorySaveHandler = _this.categorySaveHandler.bind(_this);
        _this.categoryUpdateHandler = _this.categoryUpdateHandler.bind(_this);
        return _this;
    }
    CategoryView.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var r, categories, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch('/api/product-categories')];
                    case 1:
                        r = _a.sent();
                        return [4 /*yield*/, r.json()];
                    case 2:
                        categories = _a.sent();
                        this.setState({ categories: categories });
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        console.log(error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CategoryView.prototype.render = function () {
        return (React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-6" },
                React.createElement(CategoryNew, { categorySaveHandler: this.categorySaveHandler, categoryExistsHandler: this.categoryExistsHandler, buttonIcon: this.state.buttonIcon, buttonSaveValue: this.state.buttonSaveValue, disabled: this.state.disabled, value: this.state.categoryName })),
            React.createElement("div", { className: "col-6" }),
            React.createElement("div", { className: "col-12" },
                React.createElement(CategoryList, { categories: this.state.categories, categoryDeleteHandler: this.categoryDeleteHandler, categoryUpdateParamHandler: this.categoryUpdateParamHandler, categoryUpdateHandler: this.categoryUpdateHandler, categoryUpdateCancelHandler: this.categoryUpdateCancelHandler, categoryName: this.state.categoryName, show: this.state.show }))));
    };
    return CategoryView;
}(Component));
export default CategoryView;
