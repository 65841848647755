var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import Dashboard from '../../dashboard/Dashboard';
import CustomerView from '../../customer/CustomerView';
import ProductView from '../../product/ProductView';
import CategoryView from '../../category/CategoryView';
import OffersNew from '../../assignment/OffersNew';
import OffersByYear from '../../assignment/OffersByYear';
import AssignmentsByYear from '../../assignment/AssignmentsByYear';
import BillsByYear from '../../assignment/BillsByYear';
var Content = /** @class */ (function (_super) {
    __extends(Content, _super);
    function Content() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Content.prototype.render = function () {
        var _this = this;
        return (React.createElement(Switch, null,
            React.createElement(Route, { path: "/", component: Dashboard, exact: true }),
            React.createElement(Route, { path: "/produkte/anzeigen", component: ProductView }),
            React.createElement(Route, { path: "/kategorien", component: CategoryView }),
            React.createElement(Route, { path: "/kategorien/bearbeiten/:id", component: CategoryView }),
            React.createElement(Route, { path: "/angebote/neues-angebot", component: OffersNew }),
            React.createElement(Route, { path: "/angebote/:year", render: function (props) {
                    return React.createElement(OffersByYear, __assign({}, props, { year: _this.props.year, changeAssignmentNumberHandler: _this.props.changeAssignmentNumberHandler, assignmentNumber: _this.props.assignmentNumber }));
                } }),
            React.createElement(Route, { path: "/auftraege/:year", render: function (props) {
                    return React.createElement(AssignmentsByYear, __assign({}, props, { year: _this.props.year, changeAssignmentNumberHandler: _this.props.changeAssignmentNumberHandler, assignmentNumber: _this.props.assignmentNumber }));
                } }),
            React.createElement(Route, { path: "/rechnungen/:year", render: function (props) {
                    return React.createElement(BillsByYear, __assign({}, props, { year: _this.props.year, changeAssignmentNumberHandler: _this.props.changeAssignmentNumberHandler, assignmentNumber: _this.props.assignmentNumber }));
                } }),
            React.createElement(Route, { path: "/kunden/anzeigen", component: CustomerView })));
    };
    return Content;
}(React.Component));
export default Content;
