var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import Button from '../layout/content/Button';
import ProductEdit from './ProductEdit';
var ProductList = /** @class */ (function (_super) {
    __extends(ProductList, _super);
    function ProductList(props) {
        var _this = _super.call(this, props) || this;
        /**
         * Setzt die ID der zu aktualisierenden Kategorie für die Update-SQL
         */
        _this.productUpdateParamHandler = function (props) { return __awaiter(_this, void 0, void 0, function () {
            var resultProductById, thisProduct, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch('/api/product-by-id/' + props.id)];
                    case 1:
                        resultProductById = _a.sent();
                        return [4 /*yield*/, resultProductById.json()];
                    case 2:
                        thisProduct = _a.sent();
                        if (thisProduct) {
                            this.setState({
                                productNumber: thisProduct.productNumber,
                                productName: thisProduct.productName,
                                productCategory: thisProduct.productCategory,
                                productDescription: thisProduct.productDescription,
                                show: true
                            });
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.log(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        _this.closeModalHandler = function () {
            _this.setState({
                show: false
            });
        };
        _this.productEditHandler = function (props) {
            window.location.hash = '/produkte/bearbeiten/' + props.id;
        };
        _this.state = {
            products: [],
            productNumber: 0,
            productName: "",
            productCategory: 0,
            productDescription: "",
            show: false
        };
        _this.productEditHandler = _this.productEditHandler.bind(_this);
        return _this;
    }
    ProductList.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var categoryId, resultProductByCategory, products_1, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        categoryId = this.props.categoryId;
                        return [4 /*yield*/, fetch('/api/products-by-category/' + categoryId)];
                    case 1:
                        resultProductByCategory = _a.sent();
                        return [4 /*yield*/, resultProductByCategory.json()];
                    case 2:
                        products_1 = _a.sent();
                        this.setState({
                            products: products_1
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        console.log(error_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProductList.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: "list-group" },
            this.state.products.map(function (product, key) {
                var listGroupItemStyle = "list-group-item " + _this.props.style;
                return (React.createElement("div", { className: listGroupItemStyle, key: key },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-lg-7 d-flex align-items-center" }, product.productName),
                        React.createElement("div", { className: "col-lg-1 d-flex align-items-center" }, product.productNumber),
                        React.createElement("div", { className: "col-lg-4 text-right" },
                            React.createElement("div", { className: "options" },
                                React.createElement(Button, { faClass: "fa fa-edit", title: "Produkt editieren", display: "bearbeiten", id: product.productNumber, dataToggle: true, dataTarget: "#productEditModal", clickHandler: _this.productUpdateParamHandler }),
                                React.createElement(Button, { btnClass: "add", faClass: "fa fa-plus", display: "zum Auftrag", title: "Produkt zu gew\u00E4hltem Auftrag hinzuf\u00FCgen" }))))));
            }),
            React.createElement(ProductEdit, { id: "productEditModal", productUpdateHandler: this.props.productUpdateHandler, closeModalHandler: this.closeModalHandler, productUpdateCancelHandler: this.props.productUpdateCancelHandler, productNumber: this.state.productNumber, productName: this.state.productName, productCategory: this.state.productCategory, productDescription: this.state.productDescription, show: this.state.show })));
    };
    return ProductList;
}(Component));
export default ProductList;
