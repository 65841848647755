var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import ButtonConfirm from '../layout/content/ButtonConfirm';
import Button from '../layout/content/Button';
import CategoryEdit from './CategoryEdit';
var CategoryList = /** @class */ (function (_super) {
    __extends(CategoryList, _super);
    function CategoryList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CategoryList.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", null,
            React.createElement("h5", null, "Produkt Kategorie"),
            React.createElement("div", { className: "list-group" },
                this.props.categories.map(function (category, key) {
                    return (React.createElement("div", { className: "list-group-item", key: key },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-6 d-flex align-items-center" }, category.categoryName),
                            React.createElement("div", { className: "col-lg-6 text-right" },
                                React.createElement("div", { className: "options" },
                                    React.createElement(Button, { faClass: "edit", title: "Kategorie bearbeiten", display: "bearbeiten", dataToggle: true, dataTarget: "#categoryEditModal", id: category.id, clickHandler: _this.props.categoryUpdateParamHandler }),
                                    React.createElement(ButtonConfirm, { btnClass: "delete", faClass: "trash", title: "Kategorie l\u00F6schen", display: "l\u00F6schen", confirmMessage: "Bitte best\u00E4tigen Sie, dass Sie diese Kategorie l\u00F6schen wollen", id: category.id, listItem: key, clickHandler: _this.props.categoryDeleteHandler }))))));
                }),
                React.createElement(CategoryEdit, { id: "categoryEditModal", categoryUpdateHandler: this.props.categoryUpdateHandler, categoryUpdateCancelHandler: this.props.categoryUpdateCancelHandler, categoryName: this.props.categoryName, show: this.props.show }))));
    };
    return CategoryList;
}(Component));
export default CategoryList;
