var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
var ProductNew = /** @class */ (function (_super) {
    __extends(ProductNew, _super);
    function ProductNew(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            productName: "",
            productDescription: "",
            buttonIcon: "save",
            button: "speichern"
        };
        _this.productSaveHandler = function (event) {
            event.preventDefault();
            var self = _this;
            var product = {
                productName: _this.state.productName,
                productDescription: _this.state.productDescription,
            };
            _this.setState({
                button: "wird gespeichert"
            });
            fetch('/api/product-save', {
                method: 'post',
                body: JSON.stringify(product)
            })
                .then(function (response) {
                self.setState({
                    button: "gespeichert"
                });
            })
                .catch(function (error) {
                console.log(error);
            });
        };
        _this.productNameChangeHandler = function (event) {
            _this.setState({
                productName: event.target.value
            });
        };
        _this.productDescriptionChangeHandler = function (event) {
            _this.setState({
                productDescription: event.target.value
            });
        };
        _this.productSaveHandler = _this.productSaveHandler.bind(_this);
        return _this;
    }
    ProductNew.prototype.render = function () {
        var btnClass = "btn btn-secondary " + this.state.buttonIcon;
        return (React.createElement("div", null,
            React.createElement("h5", null, "Neues Produkt"),
            React.createElement("div", { className: "container mb-5" },
                React.createElement("form", { onSubmit: this.productSaveHandler },
                    React.createElement("div", { className: "form-group" },
                        React.createElement("label", { htmlFor: "productName" }, "Produktname"),
                        React.createElement("input", { type: "text", className: "form-control", name: "productName", id: "productName", onChange: this.productNameChangeHandler, required: true })),
                    React.createElement("div", { className: "form-group" },
                        React.createElement("label", { htmlFor: "productCategories" }, "Produktkategorie"),
                        React.createElement("select", { name: "productCategories", id: "productCategories", className: "form-control", required: true },
                            React.createElement("option", null, "bitte w\u00E4hlen ..."),
                            this.props.categories.map(function (category, key) {
                                return (React.createElement("option", { key: key }, category.categoryName));
                            }))),
                    React.createElement("div", { className: "form-group" },
                        React.createElement("label", { htmlFor: "productDescription" }, "Produktbeschreibung"),
                        React.createElement("textarea", { className: "form-control", name: "productDescription", id: "productDescription", onChange: this.productDescriptionChangeHandler })),
                    React.createElement("div", { className: "form-group mb-0 text-right" },
                        React.createElement("button", { type: "submit", className: btnClass }, this.state.button))))));
    };
    return ProductNew;
}(Component));
export default ProductNew;
