var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Component } from 'react';
import CustomerList from './CustomerList';
import CustomerNew from './CustomerNew';
var ProductView = /** @class */ (function (_super) {
    __extends(ProductView, _super);
    function ProductView(props) {
        var _this = _super.call(this, props) || this;
        _this.customerSaveHandler = function (event) { return __awaiter(_this, void 0, void 0, function () {
            var self, customer, r, customerNumberNext, result, list, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.preventDefault();
                        self = this;
                        customer = {
                            customerNumber: event.target.customerNumber.value,
                            customerSalutation: event.target.customerSalutation.value,
                            customerFirstname: event.target.customerFirstname.value,
                            customerLastname: event.target.customerLastname.value,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        this.setState({
                            button: "wird gespeichert"
                        });
                        fetch('/api/customer-save', {
                            method: 'post',
                            body: JSON.stringify(customer)
                        })
                            .then(function (response) {
                            self.setState({
                                button: "gespeichert"
                            });
                        })
                            .catch(function (error) {
                            console.log(error);
                        });
                        return [4 /*yield*/, fetch('/api/get-next-customerNumber')];
                    case 2:
                        r = _a.sent();
                        return [4 /*yield*/, r.json()];
                    case 3:
                        customerNumberNext = _a.sent();
                        return [4 /*yield*/, fetch('/api/customers')];
                    case 4:
                        result = _a.sent();
                        return [4 /*yield*/, result.json()];
                    case 5:
                        list = _a.sent();
                        self.setState({
                            customers: list,
                            customerNumberNext: customerNumberNext
                        });
                        return [3 /*break*/, 7];
                    case 6:
                        error_1 = _a.sent();
                        console.log(error_1);
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        }); };
        _this.customerDeleteHandler = function (props) { return __awaiter(_this, void 0, void 0, function () {
            var self, confirm, result, list, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        self = this;
                        confirm = window.confirm("Wollen Sie diesen Benutzer wirklich löschen");
                        if (!confirm) return [3 /*break*/, 6];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, fetch('/api/customer-delete/' + props.id, {
                                method: 'post',
                            })
                                .then(function (response) {
                                // console.log(response);
                            })
                                .catch(function (error) {
                                console.log(error);
                            })];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, fetch('/api/customers')];
                    case 3:
                        result = _a.sent();
                        return [4 /*yield*/, result.json()];
                    case 4:
                        list = _a.sent();
                        self.setState({
                            customers: list,
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        error_2 = _a.sent();
                        console.log(error_2);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        _this.state = {
            customers: [],
            categories: [],
            customerNumber: 0,
            customerNumberNext: 0,
            customerSalutation: "",
            customerFirstname: "",
            customerLastname: "",
            buttonIcon: "save",
            button: "speichern"
        };
        _this.customerSaveHandler = _this.customerSaveHandler.bind(_this);
        _this.customerDeleteHandler = _this.customerDeleteHandler.bind(_this);
        return _this;
    }
    ProductView.prototype.componentDidMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var resultCustomers, customers, r, customerNumberNext, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4 /*yield*/, fetch('/api/customers')];
                    case 1:
                        resultCustomers = _a.sent();
                        return [4 /*yield*/, resultCustomers.json()];
                    case 2:
                        customers = _a.sent();
                        return [4 /*yield*/, fetch('/api/get-next-customerNumber')];
                    case 3:
                        r = _a.sent();
                        return [4 /*yield*/, r.json()];
                    case 4:
                        customerNumberNext = _a.sent();
                        this.setState({
                            customers: customers,
                            customerNumberNext: customerNumberNext
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        error_3 = _a.sent();
                        console.log(error_3);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ProductView.prototype.render = function () {
        return (React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col-6" },
                React.createElement(CustomerNew, { customerSaveHandler: this.customerSaveHandler, customers: this.state.customers, customerNumber: this.state.customerNumberNext })),
            React.createElement("div", { className: "col-6" }),
            React.createElement("div", { className: "col-12" },
                React.createElement(CustomerList, { customerDeleteHandler: this.customerDeleteHandler, customers: this.state.customers }))));
    };
    return ProductView;
}(Component));
export default ProductView;
