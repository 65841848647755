var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import url from 'url';
var NavigationItemToggle = /** @class */ (function (_super) {
    __extends(NavigationItemToggle, _super);
    function NavigationItemToggle() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NavigationItemToggle.prototype.render = function () {
        var _this = this;
        var visible = this.props.href ? "list-group-item" : "d-none";
        var expanded = false;
        if (this.props.category) {
            expanded = url.parse(window.location.href).href.split("/").indexOf(this.props.category) != -1 ? true : false;
        }
        return (React.createElement("a", { href: this.props.href, "data-toggle": "collapse", "aria-expanded": expanded, className: visible, onClick: function () { return _this.props.changeCategory; } }, this.props.value));
    };
    return NavigationItemToggle;
}(React.Component));
export default NavigationItemToggle;
